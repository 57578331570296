import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LinkExpiredPopupComponent } from './link-expired-popup/link-expired-popup.component';
import { CommonService } from './common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'client-chat-application';

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    public commonService: CommonService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey
    });

    const url = window.location.href;
    const urlSegments = url.split("=");
    this.commonService.selectedLanguage = urlSegments[urlSegments.length - 1]; 
    console.log("browser language :- ", this.commonService.selectedLanguage);
    if(this.commonService.selectedLanguage === "fr")
    {
      this.translate.setDefaultLang('fr');
    }
    else if(this.commonService.selectedLanguage === "en")
    {
      this.translate.setDefaultLang('en');
    }
    
    let expiryToken = this.extractToken(url);

    if (!expiryToken && localStorage.getItem('chatToken')) {
      expiryToken = localStorage.getItem('chatToken');
    }

    if (expiryToken) {
      localStorage.setItem('chatToken', expiryToken);
      console.log("Token extracted and used");
      this.validateTokenAndHandleResponse(expiryToken, headers);
    } else {
      console.log("Token not found in URL or localStorage");
      this.linkExpired();
    }
  }

  validateTokenAndHandleResponse(token: string, headers: HttpHeaders) {
    this.validateToken(token, headers).subscribe((response: any) => {
      console.log("Response:", response);
      if (response.statusCode === 200) {
        console.log("Status code 200");
        const connectionCredentials = response.message.connectionCredentials;
        this.commonService.expiryFlag = false;
        this.commonService.connectionToken = connectionCredentials.ConnectionCredentials.ConnectionToken;
        this.commonService.websocketUrl = connectionCredentials.Websocket.Url;
        this.commonService.ParticipantId= response.message.participantId;
        this.commonService.ContactId= response.message.chatContactId;
        this.commonService.ParticipantToken= response.message.participantToken;
        this.commonService.lang = response.message.lang;
        if(this.commonService.lang == 'fr-CA'){
          this.translate.setDefaultLang('fr');
        }else {
          this.translate.setDefaultLang('en');
        }
        console.log("WebSocket URL and connection ID:", this.commonService.websocketUrl, this.commonService.connectionToken);
      } else {
        this.linkExpired();
      }
    }, (error) => {
      console.error('Error while validating token:', error);
    });
  }

  softphoneInitCCP() {
    try {
      const ccpUrl = environment.ccpUrl;
      const containerDiv: any = document.getElementById('containerDiv');
      connect.core.initCCP(containerDiv, {
        region: "ca-central-1",
        ccpUrl: ccpUrl,
        loginPopup: false,
        loginPopupAutoClose: true,
        loginOptions: {
          autoClose: true,
          height: 600,
          width: 400,
          top: 50,
          left: 50
        },
        softphone: {
          allowFramedSoftphone: true
        },
        ccpAckTimeout: 10000,
        ccpSynTimeout: 10000,
        ccpLoadTimeout: 10000
      });
    } catch (err) {
      console.error('Error in softphoneInitCCP:', err);
    }
    const iframe: any = document.getElementsByTagName("iframe")[0];
    iframe.style.borderWidth = "0px";
  }

  linkExpired() {
    this.dialog.open(LinkExpiredPopupComponent, {
      width: '599px',
      panelClass: 'link-expired-popup',
      disableClose: true,
    });
    localStorage.removeItem('chatToken');
  }

  validateToken(token: string, headers: HttpHeaders) {
    return this.http.post(environment.validateToken, { token }, { headers });
  }

  private extractToken(url: string): string | null {
    const tokenParam = 'initiate?token=';
    const tokenIndex = url.indexOf(tokenParam);
    if (tokenIndex !== -1) {
      const start = tokenIndex + tokenParam.length;
      const end = url.indexOf('&', start) !== -1 ? url.indexOf('&', start) : url.length;
      return url.substring(start, end);
    }
    return null;
  }
}
