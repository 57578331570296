import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';

@NgModule({
  declarations: [
    CustomButtonComponent,
    CustomSnackbarComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CustomButtonComponent
  ]
})
export class SharedModule { }
