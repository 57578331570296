<div class="main-class">
    <div class="snackbar-container" *ngIf="type=='success'">
        <div class="snackbar-icon">
            <img src="../../../assets/customSnackbar/success.svg" alt>
        </div>
        <div class="snackbar-message">
            <span [innerHTML]="message"></span>
        </div>
        <div class="close-icon" (click)="close()">
            <img src="../../../assets/customSnackbar/snackbar-close.svg" alt>
        </div>

    </div>

    <div class="snackbar-container" *ngIf="type=='warning'">
        <div class="snackbar-icon">
            <img src="../../../assets/customSnackbar/warning.svg" alt>
        </div>
        <div class="snackbar-message">
            <span [innerHTML]="message"></span>
        </div>
        <div class="close-icon" (click)="close()">
            <img src="../../../assets/customSnackbar/snackbar-close.svg" alt>
        </div>
    </div>
    <div class="snackbar-container" *ngIf="type=='error'">
        <div class="snackbar-icon">
            <img src="../../../assets/customSnackbar/error.svg" alt>
        </div>
        <div class="snackbar-message">
            <span [innerHTML]="message"></span>
        </div>
        <div class="close-icon" (click)="close()">
            <img src="../../../assets/customSnackbar/snackbar-close.svg" alt>
        </div>
    </div>
</div>