import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent {
  message: string;
  type: string;
  icon: string;

  constructor(public dialogRef: MatDialogRef<CustomSnackbarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.type = data.type;
    this.icon = data.icon;

    setTimeout(() => {
      this.close();
    }, 2000);
  }
  ngOnInit(): void {
    console.log(this.data)
    console.log(this.type)
  }

  close(): void {
    // Close the snackbar
    this.dialogRef.close();
  }

}