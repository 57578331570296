export const environment = {
    production: false,
    appVersion: '',
    envName: 'crt',
    chatEndpoint: '',
    participantServiceEndpoint: 'https://participant.connect.ca-central-1.amazonaws.com',
    updateAttributes : 'https://vh0oz9vc00.execute-api.ca-central-1.amazonaws.com/int/updateContactAttributes',
    validateToken : 'https://vh0oz9vc00.execute-api.ca-central-1.amazonaws.com/crt/validateToken',
    ccpUrl : 'https://ac-connect-ae-ca-central-1-crt-3.my.connect.aws/ccp-v2',
    apiKey: 'uRj3K8Jq3e138rWfYl7Mz4ZGweoG16Ss5b5u38K4',
    translation: 'https://n966ai28gi-vpce-059c65dadefb84012.execute-api.ca-central-1.amazonaws.com/ac-crt/'
};
