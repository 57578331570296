<div class="confirm-dialog-backdrop" (click)="close()">
    <div class="confirm-dialog" (click)="$event.stopPropagation()">
        <div class="confirm-dialog-header">
            <img src="../../assets/warning_amber_red.svg">
            <p class="confirm-dialog-title">{{'customerChat.endChat' | translate}}</p>
        </div>
        <div class="confirm-dialog-body">
            <p>{{'customerChat.endChatConfirmation' | translate}}</p>
        </div>
        <div class="confirm-dialog-footer">
            <button class="footer-btn save-exit" [style.background-color]="getButtonColor()" (click)="saveAndExit()">{{'customerChat.endChat' | translate}}</button>
            <button class="footer-btn cancel" (click)="close()">{{'customerChat.cancel' | translate}}</button>
        </div>
    </div>
</div>