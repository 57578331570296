import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  expiryFlag : boolean = true
  ParticipantId: string ='';
  connectionToken: string ='';
  websocketUrl: string ='';
  ContactId:string ='';
  ParticipantToken:string ='';
  lang: string = '';
  selectedLanguage: string = "";
}
