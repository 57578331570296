import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomSnackbarComponent } from '../app/shared/custom-snackbar/custom-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationSnackbarService {
  constructor(private dialog: MatDialog) { }
  showSuccess(message: string): void {
    this.dialog.open(CustomSnackbarComponent, {
      panelClass: 'custom-notification-snackbar-dialog',
      data: { message, type: 'success', icon: 'check_circle' },
      hasBackdrop : false
    });
  }

  showWarning(message: string): void {
    this.dialog.open(CustomSnackbarComponent, {
      panelClass: 'custom-notification-snackbar-dialog',
      data: { message, type: 'warning', icon: 'warn' },
      hasBackdrop : false
    });
  }
  showError(message: string): void {
    this.dialog.open(CustomSnackbarComponent, {
      data: { message, type: 'error', icon: 'error' },
      hasBackdrop : false
    });
  }
}