import { EventEmitter, Injectable } from '@angular/core';
import 'amazon-connect-streams';
import 'amazon-connect-chatjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public chatSession: any;
  public transcript: any = [];
  public chatEnded: boolean = false;
  public chatTerminated: boolean = false;
  
  onMessageEmitter: EventEmitter<any> = new EventEmitter<any>();
  isTyping: boolean = false
  isAutoDisconnect:boolean =false;


  emitOnMessageEmitter(data: any) {
    console.log("agent chat: emitOnMessageEmitter called with data:", data);
    this.onMessageEmitter.emit(data)
  }

  constructor(private http: HttpClient,private sanitizer: DomSanitizer,) {
    this.chatEnded = false;
  }

  public async setUpChatSession(contactId: string, participantId: string, participantToken: string, _connectionToken: string): Promise<void> {
    console.log("client chat: setUpChatSession called with event:", contactId, participantId, participantToken);

    return new Promise<void>(async (resolve, reject) => {
      try {
        this.chatSession = connect.ChatSession.create({
          options: {
            region: "ca-central-1",
          },
          chatDetails: {
            contactId: contactId,
            participantId: participantId,
            participantToken: participantToken,
          },
          type: connect.ChatSession.SessionTypes.CUSTOMER,
        });

        await this.chatSession.connect();
        console.log("client chat: chatSession connected");

        this.chatSession.onMessage(async (event: any) => {
          console.log("client chat: agentChatSession.onMessage called with event:", event);

          if (event.data.ContentType == "application/vnd.amazonaws.connect.event.chat.ended") {
            this.chatEnded = true;
          }
          if (event.data.ParticipantRole=='CUSTOMER' && event.data.Type == 'ATTACHMENT') {
            const selectedTranscript = this.transcript;
            const updatedTranscript = selectedTranscript.filter((item: any) => item?.uploading !== true);
            this.transcript = updatedTranscript;            
            if (event.data?.Attachments[0].Status == "REJECTED") {
              // this.toaster.showError("Attachment may contain malware. Please verify the file.")
              console.log("Attachment may contain malware. Please verify the file.");
              return;
            }
          }
          this.emitOnMessageEmitter(event);
          this.handleOnMessageEvent(event);
        });
        this.chatSession.onParticipantIdle((event: any) => {
          this.emitOnMessageEmitter(event);
          this.handleOnMessageEvent(event);
        });
        this.chatSession.onAutoDisconnection((event: any) => {
          const { chatDetails, data } = event;
          if (data.ParticipantRole === "CUSTOMER") {
            let body = {
              "InitialContactId": chatDetails.contactId,
              "attributes": {"chatEndType": "customerIdleDisconnect",}
            }
            this.updateAttributes(body).subscribe((response: any) => {
              console.log(response);
              console.log("update function response")
            });
            this.emitOnMessageEmitter(event);
            this.handleOnMessageEvent(event);
            this.isAutoDisconnect = true;
            this.endChat(chatDetails.contactId);
            // localStorage.removeItem('chatToken');
          }       
        });
        this.chatSession.onTyping((event: any) => {
          const { chatDetails, data } = event;

          if (data.ParticipantRole === "AGENT") {
            this.isTyping = true;
            this.emitOnMessageEmitter(event)
            setTimeout(() => {
              this.isTyping = false;
            }, 10000); //10 seconds

          }
        });

        resolve();
      } catch (error) {
        console.error("Failed to set up chat session", error);
        reject(error);
      }
    });
  }
  updateAttributes(body: any) {
    console.log("Update Attributes method called");
    console.log(body);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.apiKey
    });
    return this.http.post(environment.updateAttributes, body, { headers });
  }
  public async handleOnMessageEvent(event: any) {
    const { chatDetails, data } = event;
    console.log("handleOnMessageEvent",event)
    console.log(chatDetails);
    console.log(data);
    data.sanitizeContent = this.sanitizeHtml(data?.Content);
    this.transcript.push(data);
    this.isTyping = false;
  }
  public async sendTypingEvent() {

    const awsSdkResponse = this.chatSession.sendEvent({
      contentType: "application/vnd.amazonaws.connect.event.typing"
    });

    return awsSdkResponse.data.Transcript;
  }

  public async getTranscript() {
    console.log("client chat: getTranscript called");
    const awsSdkResponse = await this.chatSession.getTranscript({
      maxResults: 100,
      sortOrder: "ASCENDING"
    });
    const { InitialContactId, NextToken, Transcript } = awsSdkResponse.data;
    this.transcript = Transcript
    for(let i = 0; i < this.transcript.length; i++){
      this.transcript[i].sanitizeContent = this.sanitizeHtml(this.transcript[i]?.Content);
    }

  }

  public async sendMessage(message: string) {
    try {
      await this.chatSession.sendMessage({
        contentType: "text/plain",
        message: message,
      });
    } catch (error) {
      console.error("Failed to send message", error);
    }
  }

  public async sendAttachment(file: File) {
    try {
      const reader = new FileReader();
      reader.onload = async (event: any) => {
        await this.chatSession.sendAttachment({
          contentType: file.type,
          attachment: file,
          attachmentName: file.name,
          AttachmentSizeInBytes: file.size
        }).then(() => {
          console.log("file uploaded3")
        });
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Failed to send attachment", error);
    }
  }

  public async getAttachment(attachmentId: string, fileName: string): Promise<string> {
    //console.log("client chat: getAttachment called with event:", attachmentId);
    try {
      await this.chatSession.downloadAttachment({ attachmentId: attachmentId })
        .then((res: any) => {
          let fileUrl = window.URL.createObjectURL(res);
          var link = document.createElement('a');
          link.href = fileUrl;
          link.download = fileName;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        })
        .catch((error: any) => {
          console.error('agent chat: Error downloading attachment:', error);
        });
    } catch (error) {
      console.error("Failed to get attachment", error);
      return undefined;
    }
    return undefined;
  }

  public endCall(contact: any) {
    console.log("client chat: endCall called with event:", contact);
    this.chatSession.disconnectParticipant();
    this.chatEnded = true;
  }

  public endChat(contact: any) {
    console.log("client chat: endCall called with event:", contact);
  //  this.chatSession.disconnectParticipant();
    this.chatEnded = true;
  }

  closeContact(contact: any) {
    contact.clear();
  }

  sanitizeHtml(content: string | undefined): SafeHtml {
    if (!content) {
      return '';
    }

    // Convert plain URLs (like www.google.com or http://example.com) into clickable links
    const urlRegex = /((https?:\/\/)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})?(\S+)?)/gi;
    const contentWithLinks = content.replace(urlRegex, (url) => {
      let hyperlink = url;
      if (!hyperlink.startsWith('http')) {
        hyperlink = 'http://' + hyperlink;
      }
      return `<a href="${hyperlink}" target="_blank">${url}</a>`;
    });

    // Parse HTML and ensure existing links have target="_blank"
    const parser = new DOMParser();
    const doc = parser.parseFromString(contentWithLinks, 'text/html');
    const links = doc.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });
    const updatedContent = doc.body.innerHTML;
    return this.sanitizer.bypassSecurityTrustHtml(updatedContent);
  }
}