import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-confirmation-pop-up',
  templateUrl: './custom-confirmation-pop-up.component.html',
  styleUrls: ['./custom-confirmation-pop-up.component.scss']
})
export class CustomConfirmationPopUpComponent {
  

  @Output() onConfirm = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();

  private colorMap: { [key: string]: string } = {
    red: '#FF3030',
    green: '#01BC47',
    blue: '#534AD8'
  };

  constructor( private translateService: TranslateService) { }

  saveAndExit() {
    this.onConfirm.emit();
  }

  close() {
    this.onCancel.emit();
  }

  getButtonColor(): string {
    return this.colorMap['red'] || this.colorMap['blue'];
  }
}
