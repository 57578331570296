<div class="mainChatContainer" *ngIf="chatContainer && !commonService.expiryFlag" (click)="loadChatWidget()">
  <div class="mainChatContainer-div">
    <div class = "chatcontainer">
    <div>
      <div class="mainChatContainer-div-2">
        <div class="mainChatContainer-div-3">
          <img loading="lazy" src="../../assets/ChatLogo.png" class="mainChatContainer-img" />
        </div>
        <div class="mainChatContainer-div-4">
          <img *ngIf="!chatService.chatEnded" (click)="disconnectChat()" loading="lazy" src="../../assets/cross.svg"
            class="mainChatContainer-img-2" />
          <img *ngIf="chatService.chatEnded" (click)="letsChat()" loading="lazy" src="../../assets/cross.svg"
            class="mainChatContainer-img-2" />
        </div>
      </div>
      <div class="mainChatContainer-div-5">
        <div *ngFor="let message of chatService.transcript">
          <div>
            <div class="event-message"
              *ngIf="message?.ContentType == 'application/vnd.amazonaws.connect.event.participant.joined' &&  message?.ParticipantRole=='AGENT'">
              <div class="event-message-text">{{message?.DisplayName}}
                {{'customerChat.joinedChat' | translate}}</div>
            </div>
            <div class="event-message"
              *ngIf="message?.ContentType == 'application/vnd.amazonaws.connect.event.participant.idle' &&  message?.ParticipantRole=='CUSTOMER'">
              <div class="event-message-text">
                {{'customerChat.customerIdleMessage' | translate}}
              </div>
            </div>
            <div class="event-message"
            *ngIf="message?.ContentType == 'application/vnd.amazonaws.connect.event.participant.autodisconnection' &&  message?.ParticipantRole=='CUSTOMER'">
            <div class="event-message-text">{{'customerChat.customerAutoDisCnctMessage' | translate}}</div>
              <div class="event-message">{{'customerChat.chatEnded' | translate}}</div>
          </div>
            <div class="mainChatContainer-div-6" *ngIf="message?.ParticipantRole=='CUSTOMER'  && message?.Content">
              <div class="msg">
                <div>
                  <!-- <div class="message-sender">{{ message?.DisplayName }}</div> -->
                  <div class="message-text" [innerHTML]="message.sanitizeContent">
                  </div>
                </div>
                <div class="message-details">
                  <!-- <span class="message-time-agent">{{
                              formatTime(message?.AbsoluteTime) }}</span> -->
                </div>
              </div>

            </div>

            <div class="mainChatContainer-div-7"
              *ngIf="(message?.ParticipantRole=='AGENT' || message?.ParticipantRole=='SYSTEM') && message?.Content ">
              <div class="msg">
                <div class="message-content">
                  <!-- <div class="message-receiver">{{ message?.DisplayName }}</div> -->
                  <div class="message-text1" [innerHTML]="message.sanitizeContent">
                  </div>
                </div>
                <div class="message-details-right">
                  <!-- <span class="message-time-customer">{{
                          formatTime(message?.AbsoluteTime) }}</span> -->
                </div>
              </div>
            </div>
            <div class="mainChatContainer-div-7"
              [ngClass]="{'mainChatContainer-div-6': message?.ParticipantRole == 'CUSTOMER'}"
              *ngIf="message?.Type == 'ATTACHMENT'">
              <div class="msg">
                <div>
                  <div class="message-attachment" *ngIf="!message?.uploading"
                    (click)="downloadAttachment(message.Attachments[0].AttachmentId,message.Attachments[0].AttachmentName )">
                    Attachment: <span class="link">{{message.Attachments[0].AttachmentName}}</span>

                  </div>
                  <div class="message-attachment" *ngIf="message?.uploading"
                  (click)="downloadAttachment(message.Attachments[0].AttachmentId,message.Attachments[0].AttachmentName )">
                  Uploading: <span class="link">{{message.Attachments[0].AttachmentName}}</span>
                </div>
                </div>
                <div class="message-details"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="message-content">
          <div class="message-text">
            <div class="typing-indicator" *ngIf="chatService.isTyping && !chatService.chatEnded">
              <div class="chat-bubble-animation">
                <div class="typing">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div #endOfChat></div>
        <div class="chat-ended-message" *ngIf="chatService.chatEnded && !chatService.isAutoDisconnect">
          <div class="event-message" *ngIf="chatService.chatEnded && !chatService.chatTerminated ">{{'customerChat.leftChat' | translate}}</div>
          <div class="event-message">{{'customerChat.chatEnded' | translate}}</div>
        </div>
      </div>
    </div>

    <div #textFormattingBar class="text-formatting-bar" style="display: none;">
      <div class="default-options">
          <img src="../../assets/format_bold.svg" class="format-option"
              (click)="formatText('bold')">
          <img src="../../assets/format_italic.svg" class="format-option"
              (click)="formatText('italic')">
          <img src="../../assets/format_underlined.svg" class="format-option"
              (click)="formatText('underline')">
          <img src="../../assets/format_list_bulleted.svg" class="format-option"
              (click)="formatText('insertUnorderedList')">
      </div>
      <div class="v-line"></div>
      <img src="../../assets/format_add_link.svg" class="format-option" (click)="formatLink()">
  </div>

  <div class="add-link-input" #addLinkContainer style="display: none;">
      <div #addLinkDiv class="text-input" contentEditable (input)="onInputAddLink($event)"
          [attr.data-placeholder]="placeholderTextAddLink" (focus)="removePlaceholderAddLink($event)"
          (paste)="handlePasteAddLink($event)" 
          (blur)="showPlaceholderAddLink($event)"></div>
      <!-- <div class="apply-button" (click)="onApplyClick()">Apply</div> -->
      <app-custom-button [type]="'primary'" [state]="enteredAddLink!=''" [color]="'default'" [size]="'small'" [text]="'Apply'"
          [textVisible]="true" (onClick)="onApplyClick()">
      </app-custom-button>
  </div>
    <div class="mainChatContainer-div-8">
      <div class="file-preview" *ngIf="selectedFile">
        <div *ngIf="!chatService.chatEnded" title="{{selectedFile.name}}">
          {{ selectedFile.name.length > 30 ? (selectedFile.name | slice:0:30) + '...' : selectedFile.name }}
        </div>
        <img src="../../assets/cross.svg" class="file-remove-img" (click)="removeSelectedFile()" />
      </div>
      <div class="mainChatContainer-div-9" *ngIf="!chatService.chatEnded">
        
          <div #messageInputBox class="message-input-box-text" contentEditable (input)="onInput($event)" spellcheck="true"
            [attr.data-placeholder]="'customerChat.writeAMessage' | translate" (focus)="removePlaceholder($event)" (blur)="showPlaceholder($event)"
            (paste)="handlePaste($event)" (keydown)="onKeydown($event)">
          </div>
     
        <img loading="lazy" src="../../assets/attachment.svg" class="mainChatContainer-img-4"
          (click)="triggerFileInput()" />
        <img (click)="sendMessage()" loading="lazy" src="../../assets/SendMessageButton.svg"
          class="mainChatContainer-img-5" />
      </div>
      <div *ngIf="!chatService.chatEnded">
        <input type="file" id="fileUpload" #fileUpload (change)="handleFileInput($event)" style="display: none;">
      </div>
      <div class="mainChatContainer-div-11">
        <div class="mainChatContainer-div-13">
          <span style="color: rgba(133, 149, 166, 1)">{{'customerChat.poweredBy' | translate}}</span>&nbsp;
          <span style="color: rgba(83, 74, 216, 1)">rozie.ai</span>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
<app-custom-confirmation-pop-up *ngIf="isDialogVisible" [title]="dialogTitle" 
    (onConfirm)="terminateChat()" (onCancel)="handleCancel()">
</app-custom-confirmation-pop-up>