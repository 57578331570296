import { Component,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-link-expired-popup',
  templateUrl: './link-expired-popup.component.html',
  styleUrl: './link-expired-popup.component.scss'
})
export class LinkExpiredPopupComponent {
  constructor(
    public dialogRef1: MatDialogRef<LinkExpiredPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    private translateService: TranslateService,
  ) { 
    // if(this.commonService.lang == 'fr-CA'){
    //   this.translateService.setDefaultLang('fr');
    // }else {
    //   this.translateService.setDefaultLang('en');
    // }
  }

  ngOnInit(){
    if(this.commonService.selectedLanguage == 'fr'){
      this.translateService.setDefaultLang('fr');
    }else {
      this.translateService.setDefaultLang('en');
    }
  }

  cancel() {
    this.dialogRef1.close(false);
  }
}
