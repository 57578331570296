// src/app/custom-button/custom-button.component.ts

import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss'],
})
export class CustomButtonComponent {
  @Input() type: 'primary' | 'secondary' | 'warn' = 'primary';
  @Input() state: boolean = true;
  @Input() color: string = 'default';
  @Input() size: 'small' | 'default' | 'large' = 'default';
  @Input() leftIcon: string | null = null;
  @Input() rightIcon: string | null = null;
  @Input() text: string = 'Save';
  @Input() textVisible: boolean = true;

  get stateClass() {
    return this.state ? 'normal' : 'disabled';
  }

  @Output() onClick = new EventEmitter<void>();

  onClickHandler() {
    this.onClick.emit();
  }
}